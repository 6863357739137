.tech-icon {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 7s ease-out, transform 7s ease-out;
  max-width: 300px;
  max-height: 300px;
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-left: 80px;
}

// Dynamically added class triggered by scroll event type
.tech-icon.reveal {
  opacity: 1;
  transform: translateY(0);
}

#tech-header {
  text-align: center;
  padding-top: 70px;
  padding-bottom: 45px;
  font-weight: bold;
}

#tech-icons {
  padding-left: 88px;
}
