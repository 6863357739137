@import "~bootstrap/scss/bootstrap";

* {
  box-sizing: border-box;
}

// From .header-overlay on home.scss
#homeDiv {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

body {
  background-color: #2d3436;
  color: white;
  // Full Screen Video
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
  overflow-x: hidden;
}

#nav-element {
  position: relative;
  z-index: 3;
}

#bioPage {
  display: none;
}

#technologiesPage {
  display: none;
}

#projectsPage {
  display: none;
}

#contactPage {
  display: none;
}

// WIP

// @media screen and (max-width:960px) { 
//   /* big landscape tablets, laptops, and desktops */ 
//   .homeDiv {
//     // background-image: url(https://images.unsplash.com/photo-1506980372018-114d8d7430ce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80);
//     background-color: pink !important;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     // z-index: 5;
//   }
// } 
