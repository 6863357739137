#projects-header {
  text-align: center;
  padding-top: 70px;
  font-weight: bold;
}

.card-body {
  color: black;
}

#project-card {
margin: 75px;
}

.card-img-top {
  // min-width: 800px;
  // min-height: 180px;
  max-width: 800px;
  max-height: 450px;
}

.card-title {
  font-weight: bold;
}