.home-page-header {
  -webkit-animation: fadein 10s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 10s; /* Firefox < 16 */
   -ms-animation: fadein 10s; /* Internet Explorer */
    -o-animation: fadein 10s; /* Opera < 12.1 */
       animation: fadein 10s;
}

@keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
from { opacity: 0; }
to   { opacity: 1; }
}

.v-header {
  height:100vh;
  display: flex;
  align-items: center;
  color: white;
}

.container {
  max-width: 960px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  text-align: center;
}

.fullscreen-video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100vh; 
  overflow: hidden;
}

.fullscreen-video-wrap video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
}          

.header-overlay {
  width: 350%;
  margin-top: -30%;
}

#homeDiv {
  background-image: url(https://images.unsplash.com/photo-1506980372018-114d8d7430ce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// WIP
// @media only screen and (max-width:1025px) { 
//   /* big landscape tablets, laptops, and desktops */ 
//   #homeDiv {
//     background-image: url(https://images.unsplash.com/photo-1506980372018-114d8d7430ce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80);
//     // background-color: pink;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//   }
// }
