#bio-photo {
  max-width: 275px; 
  max-height: 275px; 
  width: 275px; 
  height: 275px; 
  box-shadow: 1px 0px 20px white;
  border-radius: 50%;
  object-fit: cover; 
}

#bioDiv{
  padding-top: 25px;
  text-align: center;
}
