 .navbar-brand {
   max-width: 180px;
   max-height: 180px;
   font-weight: bold;
   margin-top: 45px;
   margin-left: 42px;
   color: white;
 }

a:hover {
  color: #ffda79;
}

#openNavMenu {
  position: absolute;
  right: 0;
  padding: 50px;
}

#menu {
  height: 30px;
  width: 30px;
}

// NEW NAVIGATION BAR CSS 
// https://www.w3schools.com/howto/howto_js_fullscreen_overlay.asp 
// The Overlay (background)
.overlay {
  // Height & width depends on how you want to reveal the overlay (see JS below)  
  height: 100%;
  width: 0;
  position: fixed; // Stay in place 
  z-index: 1; // Sit on top 
  left: 0;
  top: 0;
  background-color: blue; // Black fallback color 
  background-color: rgba(0,0,0, 0.9); // Black w/opacity 
  overflow-x: hidden; // Disable horizontal scroll 
  transition: 0.5s; // 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal)
}

// Position the content inside the overlay
.overlay-content {
  position: relative;
  top: 25%; // 25% from the top 
  width: 100%; // 100% width 
  text-align: center; // Centered text/links 
  margin-top: 30px; // 30px top margin to avoid conflict with the close button on smaller screens 
}

// The navigation links inside the overlay 
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: white;
  display: block; // Display block instead of inline 
  transition: 0.3s; // Transition effects on hover (color) 
}

// When you mouse over the navigation links, change their color 
.overlay a:hover, .overlay a:focus {
  color:#ffda79;
}

// Position the close button (top right corner) 
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

// When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap 
@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
